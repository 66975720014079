import { ChatBubbleOvalLeftIcon, EyeIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/outline';
import ReactionWidget from 'components/Reaction/ReactionWidget';
import { coming } from 'helpers/date';
import { shortNum } from 'helpers/shortNum';
import { Attachment } from 'modules/Attachment';
import { ComplaintTypes, useComplaintModal } from 'modules/Complaint';
import ShareButton from 'modules/Share/ShareButton';
import { Suspense, lazy, memo, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useNavigate } from 'react-router-dom';
import { Card, HStack, Stack, DatePretty, Link, Button, Pressable } from 'vgui';
import DropdownWidget from 'vgui/components/elements/DropdownWidget';

import PostHeader from './PostHeader';
import PostLegal from './PostLegal';

const PostStatisticsModal = lazy(() => import('./PostStatisticsModal'));
const PostEditorContainer = lazy(() => import('modules/Post/containers/PostEditorBar'));

const Attachments = memo(
	(props) => {
		const { items } = props;
		const types = Object.keys(items);

		let attaches = [];

		if (types.includes('repost')) return <Attachment key="repost" type="repost" {...props} items={items['repost']} />;

		if (types.includes('vacancy'))
			return <Attachment key="vacancy" type="vacancy" {...props} items={items['vacancy']} full />;

		if (types.includes('video'))
			attaches.push(<Attachment key="video" type="video" {...props} items={items['video']} />);

		if (types.includes('article'))
			attaches.push(<Attachment key="article" type="article" {...props} items={items['article']} full />);

		if (types.includes('photo'))
			attaches.push(<Attachment key="photo" type="photo" {...props} items={items['photo']} />);

		if (types.includes('link'))
			attaches.push(
				<Attachment
					key="link"
					type="link"
					{...props}
					items={items['link']}
					short={types.includes('photo') || types.includes('video') || types.includes('article')}
				/>,
			);

		return attaches;
	},
	(a, b) => a.postId === b.postId,
);

const BodyNews = (props) => {
	const { header, author, adv, body, attachments, legal, id, onEvent = () => {} } = props;

	return (
		<article className="post-news-full">
			{header && !attachments?.article && <h1>{header}</h1>}
			{attachments && <Attachments items={attachments} postId={id} onEvent={onEvent} />}
			{adv}
			{body && <Attachment type="html" content={body} full />}
			{legal && <PostLegal {...legal} author={author} full={true} />}
		</article>
	);
};

const BodyPost = (props) => {
	const { body, attachments, legal, id, author, onEvent = () => {} } = props;

	return (
		<Stack className="gap-3 post-full">
			{attachments && <Attachments items={attachments} postId={id} onEvent={onEvent} />}
			{body && !attachments?.article && <Attachment type="html" content={body} full />}
			{legal && <PostLegal {...legal} author={author} full={true} />}
		</Stack>
	);
};

const CommentsButton = ({ params, counters }) => {
	if (params.no_comments) return null;

	return (
		<Button round size="small" variant="secondary" title="Комментарии">
			<ChatBubbleOvalLeftIcon className="w-4" />
			{counters['post_comment'] || 0}
		</Button>
	);
};

const Counters = ({ id, url, counters, reaction, isAuth, params, onEvent }) => {
	return (
		<HStack className="justify-between w-full text-muted text-sm">
			<HStack className="items-center gap-2">
				<ReactionWidget
					id={id}
					values={reaction}
					isAuth={isAuth}
					onSelect={(value) => {
						onEvent('post_reaction', { ...{ value } });
					}}
				/>
				{!isAuth && <CommentsButton {...{ params, counters, isAuth }} />}
				<ShareButton url={url} />
			</HStack>
			<HStack className="items-center gap-1">
				<EyeIcon className="w-4" />
				<span className="text-xs">{shortNum(counters['post_reach'])}</span>
			</HStack>
		</HStack>
	);
};

const PostView = (props) => {
	const {
		id,
		counters,
		decoration,
		dt_publish,
		permissions,
		footer,
		// group,
		// onPressSubscribe = () => {},
		type,
		url,
		reaction,
		hide,
		deleted = false,
		onHide,
		onDelete,
		onRestore,
		params,
		onBookmark,
		isAuth,
		modal,
		editMode = false,
		onEvent = () => {},
		onClose,
		status,
		f_status,
	} = props;
	const [edit, setEdit] = useState(editMode);

	const HeaderButtons = () => {
		const showComplaintModal = useComplaintModal({ id, type: ComplaintTypes.POST });

		const [showStatModal, hideStatModal] = useModal(() => <PostStatisticsModal {...props} onClose={hideStatModal} />);

		const navigate = useNavigate();

		return (
			<HStack className="gap-x-1">
				{/* {group && <SubscribeWidget id={group?.id} access={decoration.header.access} onClick={onPressSubscribe} />} */}
				<DropdownWidget
					options={[
						isAuth && {
							value: params?.bookmark ? 'Убрать из закладок' : 'Добавить в закладки',
							onClick: () => onBookmark(!params?.bookmark),
						},
						{
							value: 'Пожаловаться',
							onClick: showComplaintModal,
						},
						permissions?.can_post_edit &&
							!edit &&
							type === 'post' && {
								value: 'Редактировать',
								onClick: () => (modal ? setEdit(true) : navigate('?w=post' + id + '&mode=edit')),
							},
						permissions?.can_post_delete && {
							value: 'Удалить пост',
							onClick: () => onDelete(id),
						},
						permissions?.can_post_stat && {
							value: 'Статистика',
							onClick: showStatModal,
						},
					]}
				/>
				{onClose && (
					<div className="w-10">
						<Button
							round
							size="rect"
							variant="transparent"
							title="Закрыть"
							onClick={!editMode && edit ? () => setEdit(false) : onClose}
						>
							<XMarkIcon className="w-5" />
						</Button>
					</div>
				)}
			</HStack>
		);
	};

	if (hide) return null;

	if (deleted)
		return (
			<Card className="bg-gray-200">
				<Card.Body className="flex flex-row justify-center items-center gap-1 p-4 leading-10">
					<span>Запись удалена.</span>
					<Pressable onClick={() => onRestore(id)} className="link text-base">
						Восстановить?
					</Pressable>
					<Button
						round
						size="rect"
						variant="danger-transparent"
						className="absolute right-4 !bg-white"
						onClick={() => onHide(id)}
					>
						<XMarkIcon className="text-red-500 w-5 h-5" />
					</Button>
				</Card.Body>
			</Card>
		);

	return (
		<Card className="!shadow-none">
			<Card.Header>
				<HStack className="justify-between w-full">
					{decoration ? (
						<PostHeader
							{...decoration.header}
							id={id}
							edit={edit}
							status={f_status === 1 ? (coming(dt_publish) ? 'Отложен' : '') : status}
							anon={params?.no_author}
						>
							<Link to={url}>
								<DatePretty value={dt_publish} />
							</Link>
						</PostHeader>
					) : (
						<div></div>
					)}
					<HeaderButtons />
				</HStack>
			</Card.Header>
			<Card.Body>
				<Suspense>
					{edit && modal ? (
						<PostEditorContainer item={props} onClose={() => setEdit(false)} onPublish={() => setEdit(false)} />
					) : type === 'post' ? (
						<BodyPost {...props} />
					) : (
						<BodyNews {...props} />
					)}
				</Suspense>
			</Card.Body>
			{!edit && !params?.draft && (
				<>
					<HStack className="grid-cols-1 p-4">
						<Counters {...{ id, url, counters, reaction, params, isAuth, onEvent }} />
					</HStack>
				</>
			)}
			{!edit && footer && <Card.Footer className="grid-cols-1">{footer}</Card.Footer>}
		</Card>
	);
};

export default PostView;
